<template>
    <div class="equipment">
        <div class="equipment_top Card-b-c">
            <Titles Titles="设备管理" />
            <div class="equipment_top_main">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="序列号:" style="margin: 0px 30px 0px 0px">
                        <el-select v-model="formInline.deviceAddress" placeholder="请选择序列号">
                            <el-option v-for="(item,index) in deviceAddressOption" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="IP:" style="margin: 0px 30px 0px 0px">
                        <el-select v-model="formInline.projectIpNew" placeholder="请选择IP">
                            <el-option v-for="(item,index) in sysDictsOptions" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="端口:" style="margin: 0px 30px 0px 0px">
                        <el-input v-model="formInline.projectCodeNew" placeholder="请输入端口"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0px">
                        <el-button type="primary" @click="onSubmit">修改</el-button>
                    </el-form-item>
                </el-form>
                <a class="bg_img">
                    <img src="@/assets/images/bgPump.png" alt="">
                </a>
            </div>
        </div>
        <div class="equipment_form Card-b-c">
            <Titles Titles="历史信息" />
            <div class="equipment_form_main">
                <template>
                    <el-table stripe :data="tableData" v-loading="loading" style="width: 100%" :header-cell-style="{'background-color': '#4073b6',color: '#FFFFFF'}">
                        <el-table-column type="index" label="序号" width="95"></el-table-column>
                        <el-table-column prop="deviceAddress" label="设备地址码" width="180"></el-table-column>
                        <el-table-column prop="projectIpOld" label="旧项目IP" width="180"></el-table-column>
                        <el-table-column prop="projectCodeOld" label="旧项目端口" width="180"></el-table-column>
                        <el-table-column prop="projectIpNew" label="新项目IP" width="180"></el-table-column>
                        <el-table-column prop="projectCodeNew" label="新项目端口" width="180"></el-table-column>
                        <el-table-column prop="updateEmp" label="修改人" width="180"></el-table-column>
                        <el-table-column prop="updateTime" label="修改时间" ></el-table-column>
                    </el-table>
                    <el-pagination
                            :hide-on-single-page="true"
                            background
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                            @current-change="handleCurrent"
                            :current-page="page"
                    >
                    </el-pagination>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Titles from '@/components/Titles'
import { getOptions, updateProjectIp, getDeviceItem } from '@/api/equipment'
export default {
  name: 'index',
  components: {
    Titles
  },
  data () {
    return {
      formInline: {
        deviceAddress: '',
        projectIpNew: '',
        projectCodeNew: ''
      },
      deviceAddressOption: [],
      sysDictsOptions: [],
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false
    }
  },
  mounted () {
    this.getOptions()
    this.getListData(this.page, this.size)
  },
  methods: {
    getOptions () {
      getOptions().then(res => {
        if (res.code == 200) {
          const { deviceAddress, sysDicts } = res.data
          this.deviceAddressOption = deviceAddress || []
          this.sysDictsOptions = sysDicts ? sysDicts.map(item => {
            return {
              value: item.id,
              label: item.dataValue
            }
          }) : []
        }
      })
    },
    getListData (page, size) {
      this.loading = true
      getDeviceItem({ page, size }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const { list, total } = res.data
          this.tableData = list
          this.total = total
        }
      })
    },
    handleCurrent (val) {
      this.getListData(val, this.size)
    },
    onSubmit () {
      const obj = Object.assign({}, this.formInline)
      const { deviceAddress, projectIpNew, projectCodeNew } = obj
      this.$confirm('修改设备信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateProjectIp({ deviceAddress, projectIpNew, projectCodeNew }).then(res => {
          if (res.code == 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment{
    .equipment_top{
        background: #FFFFFF;
        .equipment_top_main{
            padding: 30px 19px;
            position: relative;
            overflow: hidden;
            .bg_img{
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    .equipment_form{
        margin-top: 20px;
        background: #FFFFFF;
        .equipment_form_main{
            padding: 30px 19px;
        }
    }
}
</style>
