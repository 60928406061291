import { get, post } from '../common/axiosHttp.js'

// options
export const getOptions = (params) => get('/device/getIpBySysDict', params)

// 修改设备ip和端口
export const updateProjectIp = (params) => post('/device/updateProjectIp', params)

// 列表
export const getDeviceItem = (params) => get('/device/getDeviceItem',params)
